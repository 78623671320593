<template>
   <section class="about__area pt-90 pb-50">
      <div class="container">
         <div class="row">
            <div class="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
               <div class="about__thumb-wrapper">

                  <!-- <div class="about__review">
                     <h5> <span>38,200+</span> сурагч төгссөн</h5>
                  </div> -->
                  <div class="about__thumb">
                     <img src="../../assets/img/about/about.jpeg" alt="">
                  </div>
                  <!-- <div class="about__banner mt--210">
                     <img src="../../assets/img/about/about-banner.jpg" alt="">
                  </div> -->
                  <!-- <div class="about__student ml-270 mt--80">
                     <a href="#">
                        <img src="../../assets/img/about/student-4.jpg" alt="">
                        <img src="../../assets/img/about/student-3.jpg" alt="">
                        <img src="../../assets/img/about/student-2.jpg" alt="">
                        <img src="../../assets/img/about/student-1.jpg" alt="">
                     </a>
                     <p>Элссэн <span>38,000+</span> сурагчид</p>
                  </div> -->
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6">
               <div class="about__content mt-60 pr-60">
                  <div class="section__title-wrapper mb-25">
                     <h2 class="section__title">Математикийн <br><span class="yellow-bg-big">дасгалжуулагч
                     </span>А.Болортуяа </h2>
                     <p>Танд энэ өдрийн мэндийг хүргэе! </p>
                  </div>
                  <!-- <div class="about__list mb-35">
                     <ul>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Ур чадвараа дээшлүүл.</li>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Хүссэн түвшингээс хүссэн түвшиндээ хүр</li>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Хамгийн сүүлийн үеийн технологуудыг ашиглаж суралц</li>
                     </ul>
                  </div> -->
                  <router-link to="/instructor-details" class="e-btn e-btn-border">Дэлгэрэнгүй</router-link>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'AboutArea'
};
</script>
