<template>
  <header>
    <div id="header-sticky" :class="`${isSticky ? 'header__area header__transparent header__padding sticky'
      :'header__area header__transparent header__padding'} ${header__white && header__white}`">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img v-if="header__white && !isSticky"
                    src="https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/318904501_1503542700168078_2748522398242161967_n+(1)+(1).png"
                    width="75" height="50" alt="logo" />

                  <!-- <img v-else src="https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/318904501_1503542700168078_2748522398242161967_n.png" height="50"  alt="logo" /> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6">
            <div class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              ">
              <div :class="`main-menu ${header__white && 'main-menu-3'}`">
                <nav id="mobile-menu" class="d-none d-xl-block">
                  <ul>
                    <li>
                      <router-link to="/">Нүүр</router-link>
                    </li>
                    <!-- <li><router-link to="/instructor">Бидний   тухай</router-link></li> -->
                    <li class="has-dropdown">
                      <router-link to="/instructor">Бидний тухай</router-link>
                      <ul class="submenu">
                        <li>
                          <router-link to="/vision">Алсын хараа</router-link>
                        </li>
                        <li>
                          <router-link to="/mision">Зорилго</router-link>
                        </li>
                        <li>
                          <router-link to="/value">Үнэт зүйлс </router-link>
                        </li>
                        <li>
                          <router-link to="/instructor">Танилцуулга </router-link>
                        </li>
                        <li>
                          <router-link to="/rules">Ёс зүйн дүрэм </router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-dropdown">
                      <router-link to="/courses">Сургалт</router-link>
                      <ul class="submenu">
                        <li>
                          <router-link to="/matTopic">Математикийн хөтөлбөр</router-link>
                        </li>
                        <li>
                          <router-link to="/engTopic">Англи хэлний хөтөлбөр</router-link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-dropdown">
                      <!-- <li class="has-dropdown"> -->
                      <router-link to="/blog">Мэдээ мэдээлэл</router-link>
                      <ul class="submenu">
                        <li>
                          <router-link to="/news">Мэдээ</router-link>
                        </li>
                        <li>
                          <router-link to="/blog">Нийтлэл</router-link>
                        </li>
                        <li>
                          <router-link to="/interview">Ярилцлага</router-link>
                        </li>
                        <li>
                          <router-link to="/questions">Түгээмэл асуулт, хариулт</router-link>
                        </li>
                      </ul>
                    </li>
                    <!-- <ul class="submenu">
                        <li><router-link to="/blog">Blog</router-link></li>
                        <li><router-link to="/blog-details">Blog Details</router-link></li>
                      </ul> -->
                    <!-- </li> -->
                    <!-- <li class="has-dropdown">
                      <router-link to="/courses">Pages</router-link>
                      <ul class="submenu">
                        <li><router-link to="/about">About</router-link></li>
                        <li><router-link to="/instructor">Instructor</router-link></li>
                        <li>
                          <router-link to="/instructor-details">
                          Instructor Details</router-link>
                        </li>
                        <li><router-link to="/event-details">Event Details</router-link></li>
                        <li><router-link to="/cart">My Cart</router-link></li>
                        <li><router-link to="/checkout">Checkout</router-link></li>
                        <li><router-link to="/login">Sign In</router-link></li>
                        <li><router-link to="/register">Sign Up</router-link></li>
                        <li><router-link to="/errorPage">Error</router-link></li>
                      </ul>
                    </li> -->
                    <li>
                      <router-link to="/contact">Холбоо барих</router-link>
                    </li>
                  </ul>
                </nav>
              </div>
              <!-- <div class="header__search p-relative ml-50 d-none d-md-block">
                <form action="#">
                  <input type="text" placeholder="Search..." />
                  <button type="submit"><i class="fad fa-search"></i></button>
                </form>
                <div class="header__cart">
                  <a @click="handleMiniCart" href="javascript:void(0);" class="cart-toggle-btn">
                    <div class="header__cart-icon">
                      <svg viewBox="0 0 24 24">
                        <circle class="st0" cx="9" cy="21" r="1" />
                        <circle class="st0" cx="20" cy="21" r="1" />
                        <path
                          class="st0"
                          d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"
                        />
                      </svg>
                    </div>
                    <span class="cart-item">2</span>
                  </a>
                </div>
              </div> -->
              <!-- <div class="header__btn ml-20 d-none d-sm-block">
                <router-link to="/contact" class="e-btn">Онлайн гэрээ</router-link>
              </div> -->
              <div class="sidebar__menu d-xl-none">
                <div @click="handleSidebar" class="sidebar-toggle-btn ml-30" id="sidebar-toggle">
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



  <!-- cart mini area start -->
  <div class="cartmini__area">
    <div :class="`${showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`">
      <div class="cartmini__title">
        <h4>Shopping cart</h4>
      </div>
      <div @click="handleCartClose" class="cartmini__close">
        <button type="button" class="cartmini__close-btn">
          <i class="fal fa-times"></i>
        </button>
      </div>
      <div class="cartmini__widget">
        <div class="cartmini__inner">
          <ul>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="../../assets/img/course/sm/cart-1.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Strategy law and organization Foundation </a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueOne')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="value" />
                  <span @click="handleIncreaseValue('valueOne')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$46.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="../../assets/img/course/sm/cart-2.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Fundamentals of music theory Learn new</a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueTwo')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="valueTwo" />
                  <span @click="handleIncreaseValue('valueTwo')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$32.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
            <li>
              <div class="cartmini__thumb">
                <a href="#">
                  <img src="../../assets/img/course/sm/cart-3.jpg" alt="">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a href="#">Strategy law and organization Foundation </a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="handleDecreaseValue('valueThree')" class="cart-minus">-</span>
                  <input class="cart-input" type="text" v-model="valueThree" />
                  <span @click="handleIncreaseValue('valueThree')" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">$62.00</span>
                </div>
              </div>
              <a href="#" class="cartmini__del"><i class="fal fa-times"></i></a>
            </li>
          </ul>
        </div>
        <div class="cartmini__checkout">
          <div class="cartmini__checkout-title mb-30">
            <h4>Subtotal:</h4>
            <span>$113.00</span>
          </div>
          <div class="cartmini__checkout-btn">
            <router-link to="/cart" class="e-btn e-btn-border mb-10 w-100"> <span></span> view cart</router-link>
            <router-link to="/checkout" class="e-btn w-100"> <span></span> checkout</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- cart mini area end -->

  <!-- sidebar area start -->
  <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
    <div class="sidebar__wrapper">
      <div @click="handleSidebarClose" class="sidebar__close">
        <button class="sidebar__close-btn" id="sidebar__close-btn">
          <span><i class="fal fa-times"></i></span>
          <span>Хаах</span>
        </button>
      </div>
      <div class="sidebar__content">
        <div class="logo mb-40">
          <router-link to="/">
            <img src="../../assets/img/logo/logo.png" width="75" height="50" alt="logo">
          </router-link>
        </div>
        <div class="side-info-content sidebar-menu mm-menu">
          <ul>
            <li>
              <router-link to="/">Нүүр</router-link>
            </li>
            <li class="menu-item-has-children has-droupdown"
              :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
              <a @click="menuOption.coursesDropdown = !menuOption.coursesDropdown">Бидний тухай</a>
              <ul class="sub-menu" :class="[menuOption.coursesDropdown === true ? 'active' : '',]">
                <li>
                  <router-link to="/vision">Алсын хараа</router-link>
                </li>
                <li>
                  <router-link to="/mision">Зорилго</router-link>
                </li>
                <li>
                  <router-link to="/value">Үнэт зүйлс</router-link>
                </li>
                <li>
                  <router-link to="/instructor">Танилцуулга</router-link>
                </li>
                <li>
                  <router-link to="/rules">Ёс зүйн дүрэм</router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item-has-children has-droupdown"
              :class="[menuOption.blogDropdown === true ? 'active' : '']">
              <a v-on:click="menuOption.blogDropdown = !menuOption.blogDropdown">Сургалт</a>
              <ul class="sub-menu" :class="[menuOption.blogDropdown === true ? 'active' : '',]">
                <li>
                    <router-link to="/matTopic">Математикийн хөтөлбөр</router-link>
                </li>
                <li>
                    <router-link to="/engTopic">Англи хэлний хөтөлбөр</router-link>
                </li>
              </ul>
            </li>
            <li class="menu-item-has-children has-droupdown"
              :class="[menuOption.pagesDropDown === true ? 'active' : '',]">
              <a @click="menuOption.pagesDropDown = !menuOption.pagesDropDown">Мэдээ мэдээлэл</a>
              <ul class="sub-menu" :class="[menuOption.pagesDropDown === true ? 'active' : '',]">
                <li>
                    <router-link to="/news">Мэдээ</router-link>
                </li>
                <li>
                    <router-link to="/blog">Нийтлэл</router-link>
                </li>
                <li>
                    <router-link to="/interview">Ярилцлага</router-link>
                </li>
                <li>
                    <router-link to="/questions">Түгээмэл асуулт, хариулт</router-link>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/contact" class="border-0">Холбоо барих</router-link>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
  <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
</template>

<script>
  export default {
    name: "HomeHeader",
    props: {
      header__white: String,
    },
    data() {
      return {
        isSticky: false,
        showMiniCart: false,
        showSidebar: false,
        value: 1,
        valueTwo: 1,
        valueThree: 1,
        menuOption: {
          menuShow: false,
          menuSearch: false,
          homeDropdown: false,
          coursesDropdown: false,
          pagesDropDown: false,
          blogDropdown: false,
          aboutDropdown: false,
        },
      }
    },
    methods: {
      handleSticky() {
        if (window.scrollY > 80) {
          this.isSticky = true;
        } else {
          this.isSticky = false;
        }
      },
      handleMiniCart() {
        this.showMiniCart = true;
      },
      handleCartClose() {
        this.showMiniCart = false;
      },
      handleSidebar() {
        this.showSidebar = true;
      },
      handleSidebarClose() {
        this.showSidebar = false;
      },
      handleIncreaseValue(value) {
        if (value === 'valueOne') {
          this.value++
        }
        if (value === 'valueTwo') {
          this.valueTwo++
        }
        if (value === 'valueThree') {
          this.valueThree++
        }

      },
      handleDecreaseValue(value) {
        if (value === 'valueOne' && this.value > 0) {
          this.value--
        }
        if (value === 'valueTwo' && this.valueTwo > 0) {
          this.valueTwo--
        }
        if (value === 'valueThree' && this.valueThree > 0) {
          this.valueThree--
        }
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleSticky)
    },
  };
</script>