 <template>
     <div>
         <Header />
             <HeroArea/>
         <AboutArea />
         
         <Services />
         <!-- <Blog/> -->
            <!-- <Course/> -->
         <!-- <Course /> -->
         <Review />
         <Events />
         <Teachers />
         <!-- <Counter /> -->
         <Testimonial />
         <Footer footerPadding="true" />
         <el-dialog v-model="puzzleReadStatus" :close-on-click-modal="false" :show-close="false" width="0%">
             <div class="quez-background">
                 <el-image
                     v-if="check_value.includes('parent') && check_value.length == 1 || check_value.includes('teacher') && check_value.length == 1  || check_value.includes('me') && check_value.length == 1 "
                     style="width: 200px; height: 200px" :src="logo_one" fit="cover" />
                 <el-image
                     v-if="check_value.includes('teacher') && check_value.includes('parent') && check_value.length == 2 || check_value.includes('teacher') && check_value.includes('me') && check_value.length == 2 || check_value.includes('parent') && check_value.includes('me') && check_value.length == 2"
                     style="width: 200px; height: 200px" :src="logo_two" fit="cover" />
                 <el-image
                     v-if="check_value.includes('teacher') && check_value.includes('parent') && check_value.includes('me') && check_value.length == 3"
                     style="width: 200px; height: 200px" :src="logo_three" fit="cover" />
                 <div class="question">
                     <span>Хүүхдийн суралцах сэтгэлзүйд нөлөөлдөг <br />хамгийн чухал 3-н хүчин зүйлийг сонгоно
                         уу?</span>
                     <div class="selected_value">
                         <el-checkbox-group v-model="check_value">
                             <el-checkbox label="parent" value>Эцэг эх</el-checkbox>
                             <!-- <el-checkbox label="emee" >Эмээ өвөө</el-checkbox> -->
                             <!-- <el-checkbox label="friend">Найз нөхөд</el-checkbox> -->
                             <el-checkbox label="teacher">Багш</el-checkbox>
                             <el-checkbox label="me">Сурагч өөрөө</el-checkbox>
                         </el-checkbox-group>
                     </div>
                 </div>
             </div>
         </el-dialog>
     </div>
 </template>
 <script>
     import Header from '../components/Home/Header.vue';
     import Services from '../components/HomeTwo/Services.vue';
     import Events from '../components/Home/Events.vue';
     import Teachers from '../components/HomeThree/Teachers.vue';
     import Footer from '../components/Home/Footer.vue';
     import Testimonial from '../components/HomeThree/Testimonial.vue';
     import Review from '../components/HomeTwo/Testimonial.vue';
     import LogoOne from '../assets/img/one.png'
     import LogoTwo from '../assets/img/two.png'
     import HeroArea from '../components/HomeTwo/HeroArea.vue';
     import LogoThree from '../assets/full_logo.png'
     import AboutArea from '@/components/common/AboutArea.vue'
     
    import { mapGetters } from 'vuex'
     export default {
         name: 'HomePage',
         components: {
             Header,
             Events,
             Footer,
             Services,
             Teachers,
             Testimonial,
             Review,
             HeroArea,
             AboutArea
        },
        computed: mapGetters({
        puzzleReadStatus: 'puzzleReadStatus'
        }),
         data() {
             return {
                 logo_one: LogoOne,
                 logo_two: LogoTwo,
                 logo_three: LogoThree,
                 check_value: []
             }
         },
         watch: {
             check_value() {
                 if (this.check_value.includes('teacher') && this.check_value.includes('parent') && this.check_value.includes('me') && this.check_value.length == 3) {
                     setTimeout(() => {
                        this.$store.commit('changeStatusPuzzle', false)
                     }, 1000);
                 }

             }

         },
         methods: {}
     }
 </script>