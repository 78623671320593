<template>
   <section class="contact__area pt-115 pb-120">
      <div class="container">
         <div class="row">
            <div class="col-xxl-7 col-xl-7 col-lg-6">
               <div class="contact__wrapper">
                  <div class="section__title-wrapper mb-40">
                     <h2 class="section__title" ><span class="yellow-bg yellow-bg-big">Санал хүсэлт<img
                              src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                     <p>Таньд асууж тодруулах зүйл байвал бичиж үлдээнэ үү? </p>
                  </div>
                  <div class="contact__form">
                     <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input v-model="reqForm.user_name" type="text" placeholder="Таны нэр">
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input  type="text" maxlength="8" size="8" v-model="reqForm.phone" placeholder="Таны утасны дугаар">
                           </div>
                        </div>
                        <!-- <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <input type="text" placeholder="Гарчиг" name="subject">
                           </div>
                        </div> -->
                        <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <textarea v-model="reqForm.description"
                                 placeholder="Асуух гэж буй зүйлээ бичнэ үү"></textarea>
                           </div>
                        </div>
                        <!-- <div class="col-xxl-12">
                           <div class="contact__form-agree  d-flex align-items-center mb-20">
                              <input class="e-check-input" type="checkbox" id="e-agree">
                              <label class="e-check-label" for="e-agree">I agree to the<a href="#">Terms & Conditions</a></label>
                           </div>
                        </div> -->
                        <div class="col-xxl-12">
                           <div class="contact__btn">
                              <button @click="sendRequest()" class="e-btn">Илгээх</button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
               <div class="contact__info white-bg p-relative z-index-1">
                  <div class="contact__shape">
                     <img class="contact-shape-1" src="../../assets/img/contact/contact-shape-1.png" alt="">
                     <img class="contact-shape-2" src="../../assets/img/contact/contact-shape-2.png" alt="">
                     <img class="contact-shape-3" src="../../assets/img/contact/contact-shape-3.png" alt="">
                  </div>
                  <div class="contact__info-inner white-bg">
                     <ul>
                        <li>
                           <div class="contact__info-item d-flex align-items-start mb-35">
                              <div class="contact__info-icon mr-15">
                                 <svg class="map" viewBox="0 0 24 24">
                                    <path class="st0" d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z" />
                                    <circle class="st0" cx="12" cy="10" r="3" />
                                 </svg>
                              </div>
                              <div class="contact__info-text">
                                 <h4>Хаяг байршил</h4>
                                 <p><a target="_blank"
                                       href="https://www.google.com/maps/place/Dhaka/@23.7806207,90.3492859,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.8104753!4d90.4119873">
                                       Улаанбаатар хот, Баянгол дүүрэг, 2-р хороо,Энхтайваны өргөн чөлөө, Баруун 4-н зам, "Жем палас" төв, 13 давхарт</a>
                                 </p>
                              </div>
                           </div>
                        </li>
                        <li>
                           <div class="contact__info-item d-flex align-items-start mb-35">
                              <div class="contact__info-icon mr-15">
                                 <svg class="mail" viewBox="0 0 24 24">
                                    <path class="st0"
                                       d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z" />
                                    <polyline class="st0" points="22,6 12,13 2,6 " />
                                 </svg>
                              </div>
                              <div class="contact__info-text">
                                 <h4>Мэйл хаяг</h4>
                                 <p><a href="mailto:support@educal.com">info@dma.mn</a></p>
                                 <!-- <p><a href="mailto:info@educal.com"> bolortuya@dma.mn</a></p> -->
                              </div>
                           </div>
                        </li>
                        <li>
                           <div class="contact__info-item d-flex align-items-start mb-35">
                              <div class="contact__info-icon mr-15">
                                 <svg class="call" viewBox="0 0 24 24">
                                    <path class="st0"
                                       d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z" />
                                 </svg>
                              </div>
                              <div class="contact__info-text">
                                 <h4>Phone</h4>
                                 <p><a href="tel:+(976)-77-77-77-57">77 77 77 57</a></p>
                                 <p><a href="tel:+(976)-77-77-77-57">77 77 77 56</a></p>
                              </div>
                           </div>
                        </li>
                     </ul>
                     <div class="contact__social pl-30">
                        <h4>Follow Us</h4>
                        <ul>
                           <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                           <li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li>
                           <li><a href="#" class="pin"><i class="fab fa-pinterest-p"></i></a></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
   import axios from 'axios'
   import config from '@/config/index'
   export default {
      name: 'ContactArea',
      data() {
         return {
            reqForm: {
               user_name: '',
               phone: null,
               status: 10,
               description: ''
            }
         }
      },
      methods: {
         sendRequest() {
            var self = this
            if(self.reqForm.phone != null){
                   axios.post(config.BASE_SERVER_PATH + '/createUserRequest', self.reqForm)
               .then(function (response) {
                  if (response.data.status == 'success') {
                     self.$notify({
                        title: 'Танд баярлалаа',
                        message: 'Таны хүсэлтийг хүлээн авлаа бид таньтай маш хурданаар эргэн холбогдоно баярлалаа.',
                        type: 'success'
                     });
                  }
                  console.log(response)
               })
               .catch(function (error) {
                  console.log(error);
               });
            }else{
                self.$notify.error({
          title: 'Уучлаарай',
          message: 'Таньтай эргэн холбогдох утасны дугаарыг бидэнд илгээнэ үү.'
        });
            }
         }
      }
   };
</script>