<template>
  <div>
    <Header header__white="header__white" />
   <Breadcrumb title="Эрхэм зорилго" subtitle="Эрхэм зорилго" />
       <div class="row">
         <div class="col-xxl-4 offset-xxl-4">
            <div class="section__title-wrapper mb-60 mt-60 text-center">
            </div>
         </div>
      </div>
      <section class="container value__area pt-50 pb-100">
         <div class="value_story">
            <b style="font-size:30px">Монгол хүний оюуны чадамжийг дэлхийд таниулах, үнэлэмжийг нэмэгдүүлэх нь бидний эрхэм зорилго</b>
         </div>
      </section>
      <!-- <div class="row mb-100 mt-50">
         <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div class="mission__tab-content">
               <div class="tab-content">
                  
                  <div class="tab-pane fade show active">
                     <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                           <div class="mission__item grey-bg mb-30 p-relative">
                              <div class="mission__tag mb-0">
                                 <h4>Монгол хүний оюуны чадамжийг дэлхийд таниулах, үнэлэмжийг нэмэгдүүлэх нь бидний эрхэм зорилго</h4>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
            </div>
         </div>
      </div> -->
       <Footer footerPadding="true" />
  </div>
</template>

<script>
   import Header from '../components/Home/Header.vue';
   import Breadcrumb from '../components/common/Breadcrumb.vue';
   import Footer from '../components/Home/Footer.vue';
export default {
    name:"MisionPage",
    components:{
        Header,
        Breadcrumb,
        Footer
    }
}
</script>

<style>

</style>