<template>
 <footer>
   <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <div class="footer__logo">
                           <router-link to="/">
                              <img width="150" :src="`${footerBgTwo ? require('../../assets/img/logo/logo.png') : require('../../assets/img/logo/logo-2.png')}`" alt="">
                           </router-link>
                        </div>
                     </div>
                     <div :class="`${footerBgTwo ? 'footer__widget-body-2' : 'footer__widget-body'}`">
                        <p>Улаанбаатар хот, Баянгол дүүрэг, 2-р хороо,Энхтайваны өргөн чөлөө, Баруун 4-н зам, "Жем палас" төв, 13 давхарт</p>

                        <div class="footer__social">
                           <ul>
                              <li><a href="https://www.facebook.com/Delkhiinmedleg"><i class="fab fa-facebook-f"></i></a></li>
                              <!-- <li><a href="#" class="tw"><i class="fab fa-twitter"></i></a></li> -->
                              <li><a href="https://www.youtube.com/@user-hh9tj7qs1b" class="pin"><i class="fab fa-youtube"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Компани</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/">Нүүр хуудас</a></li>
                              <li><a href="/vision">Алсын хараа</a></li>
                              <li><a href="/mision">Зорилго</a></li>
                              <li><a href="/value">Үнэт зүйлс</a></li>
                              <li><a href="/instructor">Танилцуулга</a></li>
                              <li><a href="/rules">Ёс зүйн дүрэм</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6">
                  <div class="footer__widget mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Үндсэн</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__link-2' : 'footer__link'}`">
                           <ul>
                              <li><a href="/matTopic">Математикийн хөтөлбөр</a></li>
                              <li><a href="/engTopic">Англи хэлний хөтөлбөр</a></li>
                              <li><a href="/news">Мэдээ</a></li>
                              <li><a href="/blog">Нийтлэл</a></li>
                              <li><a href="/interview">Ярилцлага</a></li>
                              <li><a href="/questions">Түгээмэл асуулт, хариулт</a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
                  <div class="footer__widget footer__pl-70 mb-50">
                     <div class="footer__widget-head mb-22">
                        <h3 :class="`${footerBgTwo ? 'footer__widget-title-2' : 'footer__widget-title'}`">Холбоо барих</h3>
                     </div>
                     <div class="footer__widget-body">
                        <div :class="`${footerBgTwo ? 'footer__subscribe-2' : 'footer__subscribe'}`">
                           <form action="#">
                              <div class="footer__subscribe-input mb-15">
                                 <input type="email" placeholder="Та утасны дугаараа үлдээнэ үү">
                                 <button type="submit">
                                    <i class="far fa-arrow-right"></i>
                                    <i class="far fa-arrow-right"></i>
                                 </button>
                              </div>
                           </form>
                           <p>Бид таньлуу аль болох хурдан холбоо барих болно.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div :class="`${footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'}`">
         <div class="container">
            <div class="row">
               <div class="col-xxl-12">
                  <div :class="`${footerBgTwo ? 'footer__copyright-2 text-center' : 'footer__copyright text-center'}`">
                     <p>© 2022 Дэлхийн мэдлэг акедеми</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</footer>
</template>

<script>
export default {
   name:'FooterArea',
   props:{
      footerBgTwo:String,
      footerPadding:String,
   }
};
</script>
