export default {
    data() {
        return {
           courseItems:  [
               {
                   id: 1,
                   courseImage: require(`@/assets/img/course/course-1.png`),
                   listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   lesson:'10',
                   title: 'Математик 4 анги.',
                   rating: '4.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   teacherName: 'А.Болортуяа',
                   category: 'Бага анги',
                   price:'300.000',
                   oldPrice:'350.000'
               },
               {
                   id: 2,
                   courseImage: require(`@/assets/img/course/course-2.png`),
                   listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                   lesson:'72',
                   title: 'Англи хэл анхан шат',
                   rating: '4.0',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-2.jpg`),
                   teacherName: 'А.Молор-Эрдэнэ',
                   category: 'Англи хэл',
                   price:'300.000',
                   oldPrice:'350.000',
                   color:'sky-blue'
               },
               {
                id: 1,
                courseImage: require(`@/assets/img/course/course-1.png`),
                listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                lesson:'10',
                title: 'Математик 4 анги.',
                rating: '4.5',
                teacherImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                teacherName: 'А.Болортуяа',
                category: 'Бага анги',
                price:'300.000',
                oldPrice:'350.000'
            },
            {
                id: 2,
                courseImage: require(`@/assets/img/course/course-2.png`),
                listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                lesson:'72',
                title: 'Англи хэл анхан шат',
                rating: '4.0',
                teacherImg: require(`@/assets/img/course/teacher/teacher-2.jpg`),
                teacherName: 'А.Молор-Эрдэнэ',
                category: 'Англи хэл',
                price:'300.000',
                oldPrice:'350.000',
                color:'sky-blue'
            },
            {
                id: 1,
                courseImage: require(`@/assets/img/course/course-1.png`),
                listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                lesson:'10',
                title: 'Математик 4 анги.',
                rating: '4.5',
                teacherImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                teacherName: 'А.Болортуяа',
                category: 'Бага анги',
                price:'300.000',
                oldPrice:'350.000'
            },
            {
                id: 2,
                courseImage: require(`@/assets/img/course/course-2.png`),
                listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                lesson:'72',
                title: 'Англи хэл анхан шат',
                rating: '4.0',
                teacherImg: require(`@/assets/img/course/teacher/teacher-2.jpg`),
                teacherName: 'А.Молор-Эрдэнэ',
                category: 'Англи хэл',
                price:'300.000',
                oldPrice:'350.000',
                color:'sky-blue'
            },
            {
                id: 1,
                courseImage: require(`@/assets/img/course/course-1.png`),
                listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                lesson:'10',
                title: 'Математик 4 анги.',
                rating: '4.5',
                teacherImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                teacherName: 'А.Болортуяа',
                category: 'Бага анги',
                price:'300.000',
                oldPrice:'350.000'
            },
            {
                id: 2,
                courseImage: require(`@/assets/img/course/course-2.png`),
                listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                lesson:'72',
                title: 'Англи хэл анхан шат',
                rating: '4.0',
                teacherImg: require(`@/assets/img/course/teacher/teacher-2.jpg`),
                teacherName: 'А.Молор-Эрдэнэ',
                category: 'Англи хэл',
                price:'300.000',
                oldPrice:'350.000',
                color:'sky-blue'
            },
           ]
        }
    }
}