import { createWebHistory,createRouter } from "vue-router";
import Home from '../pages/Home.vue';
// import HomeTwo from '../pages/HomeTwo.vue';
// import HomeThree from '../pages/HomeThree.vue';
import Courses from '../pages/Courses.vue';
// import CoursesList from '../pages/CoursesList.vue';
// import CourseSidebar from '../pages/CourseSidebar.vue';
import CourseDetails from '../pages/CourseDetails.vue';
import Blog from '../pages/Blog.vue';
import BlogDetails from '../pages/BlogDetails.vue';
// import About from '../pages/About.vue';
import Instructor from '../pages/Instructor.vue';
import InstructorDetails from '../pages/InstructorDetails.vue';
import EventDetails from '../pages/EventDetails.vue';
// import Cart from '../pages/Cart.vue';
// import Checkout from '../pages/Checkout.vue';
// import SignIn from '../pages/SignIn.vue';
// import SignUp from '../pages/SignUp.vue';
import ErrorPage from '../pages/ErrorPage.vue';
import Contact from '../pages/Contact.vue';
import CourseDetailsPage from '../pages/DynamicCourseDetails.vue';
import DynamicBlogDetails from '../pages/DynamicBlogDetails.vue';
import Matematik1 from '../pages/Marematik1.vue';
import MatematicTopic from '../pages/MatematicTopic.vue';
import EnglishTopic from '../pages/EnglishTopic.vue';
// import EnglishServices from '../pages/EnglishServices.vue';
//import MatematicServices from '../pages/MatematicServices.vue';
import Rule from '../pages/rules.vue'
import Vision from '../pages/Vision.vue'
import Mision from '../pages/Mision.vue'
import Value from '../pages/Value.vue'
import News from '../pages/News.vue'
import Interview from '../pages/Interview.vue'
import Questions from '../pages/Questions.vue'
const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/',
            component:Home,
        },
        {
            path:'/home',
            component:Home,
        },
        // {
        //     path:'/home-two',
        //     component:HomeTwo,
        // },
        // {
        //     path:'/home-three',
        //     component:HomeThree,
        // },
        {
            path:'/courses',
            component:Courses,
        },
        // {
        //     path:'/courses-list',
        //     component:CoursesList,
        // },
        // {
        //     path:'/courses-sidebar',
        //     component:CourseSidebar,
        // },
        {
            path:'/course-details',
            component:CourseDetails,
        },
        {
            path:'/blog',
            component:Blog,
        },
        {
            path:'/blog-details',
            component:BlogDetails,
        },
        // {
        //     path:'/about',
        //     component:About,
        // },
        {
            path:'/instructor',
            component:Instructor,
        },
        {
            path:'/instructor-details',
            component:InstructorDetails,
        },
        {
            path:'/event-details',
            component:EventDetails,
        },
        {
            path:'/mat1',
            name:"mat1",
            component:Matematik1,
        },
        {
            path:'/rules',
            name:"Rule",
            component:Rule,
        },
        {
            path:'/vision',
            name:"Vision",
            component:Vision,
        },
        {
            path:'/matTopic',
            name:"matTopic",
            component:MatematicTopic,
        },
        {
            path:'/engTopic',
            name:"engTopic",
            component:EnglishTopic,
        },
        // {
        //     path:'/engServices',
        //     name:"engServices",
        //     component:EnglishServices,
        // },
        // {
        //     path:'/matServices',
        //     name:"matServices",
        //     component:MatematicServices,
        // },
        {
            path:'/mision',
            name:"Mision",
            component:Mision,
        },
        {
            path:'/value',
            name:"Value",
            component:Value,
        },
        {
            path:'/news',
            name:"News",
            component:News,
        },
        {
            path:'/interview',
            name:"Interview",
            component:Interview,
        },
        {
            path:'/questions',
            name:"Questions",
            component:Questions,
        },
        // {
        //     path:'/checkout',
        //     component:Checkout,
        // },
        // {
        //     path:'/login',
        //     component:SignIn,
        // },
        // {
        //     path:'/register',
        //     component:SignUp,
        // },
        {
            path:'/errorPage',
            component:ErrorPage,
        },
        {
            path:'/contact',
            component:Contact,
        },
        {
            path:'/course-details/:id',
            component:CourseDetailsPage,
        },
        {
            path:'/blog-details/:id',
            component:DynamicBlogDetails,
        },
    ]
})

router.beforeEach((to, from, next) => {
    next();
    window.scrollTo(0, 0)
});

export default router