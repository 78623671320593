<template>
 <section class="page__title-area pt-120">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="../../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="../../assets/img/page-title/page-title-shape-2.png" alt="">
      <img class="page-title-shape-7" src="../../assets/img/page-title/page-title-shape-4.png" alt="">
      <img class="page-title-shape-8" src="../../assets/img/page-title/page-title-shape-5.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-9 col-xl-8">
            <div class="page__title-content mb-25 pr-40">
               <div class="page__title-breadcrumb">                            
                     <nav aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><router-link to="/">Нүүр</router-link></li>
                        <li class="breadcrumb-item"><router-link to="/">Түгээлмэл асуулт хариулт</router-link></li>
                        <!-- <li class="breadcrumb-item active" aria-current="page">Шууд тухайн ангийн хичээлийг зааж болох уу?</li> -->
                     </ol>
                     </nav>
               </div>
               <h5 class="page__title-3">{{eventHeadDetail.question}}?</h5>
            </div>
            <div class="course__meta-2 d-sm-flex mb-30">
                  <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                  <div class="course__teacher-thumb-3 mr-15">
                     <img src="../../assets/img/course/teacher/teacher-1.jpg" alt="">
                  </div>
                  <div class="course__teacher-info-3">
                     <h5>Багш</h5>
                     <p><a href="#">А.Болортуяа</a></p>
                  </div>
               </div>
               <div class="course__update mr-80 mb-30">
                  <h5>Огноо:</h5>
                  <p>{{getFormattedDate(eventHeadDetail.created_at)}}</p>
               </div>
               <div class="course__update mb-30">
                  <h5>Байршил:</h5>
                  <p>Улаанбаатар</p>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
import moment from 'moment'
export default {
   name:'EventDetailsTitle',
   props:{
      eventHeadDetail:Object
   },
   methods:{
          getFormattedDate(date) {
            return moment(date).format("YYYY-MM-DD HH:mm:ss")
        },
   }
};
</script>
