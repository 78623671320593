export default {
    data() {
        return {
           blogItems:  [
               {
                   id: 1,
                   blogImg:(`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Musun+uul_nuur.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'Хүүхдийн мөсөн уул',
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: 'А. Болортуяа',
                //    category: 'Art & Design',
                   date:'2023 оны 2 сарын 14',
                   color:'green'
               },
               {
                   id: 2,
                   blogImg: (`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Udur+buriin+nar_nuur.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'Өдөр бүрийн нар',
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: 'А. Болортуяа',
                //    category: 'UX Design',
                   date:'2023 оны 2 сарын 14',
                   color:'sky-blue'
               },
               {
                   id: 3,
                   blogImg:(`https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/blog+-+Huuuhed+tanii+geriin+toli.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'Хүүхэд танай гэр бүлийн толь',
                   authorImg: require(`@/assets/img/course/teacher/teacher-1.jpg`),
                   authorName: 'А. Болортуяа',
                //    category: 'Development',
                   date:'2023 оны 2 сарын 14',
                   color:'green',
               }
           ]
        }
    }
}