<template>
    <section class="teacher__area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-xxl-6 offset-xxl-3">
                    <div class="section__title-wrapper text-center mb-60">
                        <span  class="teachers_title">Бид таньд<br>
                             туслах болно. <br>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                    <div class="teacher__item text-center transition-3 mb-30">
                        <div class="teacher__thumb w-img transition-3 fix" style="border-radius:10px">
                             <el-image
                        src="https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/316863703_1187835252156200_3560982380002790111_n.png"
                        :fit="fit"></el-image>
                            <!-- <a href="#">
                                <img v-if="teacher.img_url" :src="'https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/'+teacher.img_url" alt="">
                                <img v-if="teacher.img_url" :src="teacher.img_url" alt="">
                                <img v-else
                                    src="https://media.istockphoto.com/photos/female-portrait-icon-as-avatar-or-profile-picture-picture-id477333976?k=20&m=477333976&s=612x612&w=0&h=3OaYU8gCXxKjdzdKUc9MokXV8q0vAVk2J3dBtabmtpE="
                                    alt="">
                            </a> -->
                        </div>
                        <!-- <div class="teacher__content">
                            <h3 class="teacher__title">
                                <router-link to="/teacher-details">{{teacher.name}}</router-link>
                            </h3> <br />
                            <span v-if="teacher.position"> {{teacher.position.position_name}}</span>
                            <div class="teacher__social">
                                <ul>
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-vimeo-v"></i></a></li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
            </div>
            <div class="row">
                <div class="col-xxl-12">
                    <div class="teacher__more text-center mt-30">
                        <router-link to="/instructor" class="e-btn e-btn-border e-btn-5">Бүгдийг харах</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import axios from 'axios'
    import config from '@/config/index'
    export default {
        name: 'TeachersArea',
        data() {
            return {
                TeachersData: [{
                        id: 1,
                        img: (`https://i.ibb.co/x5RCWNF/Untitled.png`),
                        name: 'Г.Болдбаатар',
                        title: 'Гүйцэтгэх захирал',
                    },
                    {
                        id: 2,
                        img: (`https://i.ibb.co/V2gRQtp/Untitled-1.png`),
                        name: 'А.Болортуяа',
                        title: 'Математик багш',
                    },
                    {
                        id: 3,
                        img: (`https://i.ibb.co/zGvsv0J/Untitled-2.png`),
                        name: 'Г.Мөнхзаяа',
                        title: 'Математик багш',
                    }
                ]
            }
        },
        created() {
            this.getEmplooyeesData()
        },
        methods: {
            getEmplooyeesData() {
                var self = this
                axios.get(config.BASE_SERVER_PATH + '/getUserListWeb')
                    .then(function (response) {
                        self.TeachersData = response.data.result
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    };
</script>