<template>
   <Header header__white="header__white" />
   <Breadcrumb title="Мэдээ мэдээлэл" subtitle="Мэдээ мэдээлэл" />
   <div class="service_detail_body m-b-100 inline_height" v-for="(detail, index) in detailList" :key="index">
      <div v-if="lesson == detail.value">
         <h2 class="section__title service_detail_body_title">{{detail.title}}</h2>
         <div class="service_detail_description m-t-50">
            {{detail.description}}
         </div>
         <el-row :gutter="18" class="row-bg m-t-50" justify="space-evenly">
            <el-col :span="6" v-for="(sub,i) in detail.detailItems" :key="i">
               <div class="service_detail_item box_height">
                  <span class="service_detail_item_title">
                     {{sub.item_title}}
                  </span>
                  <div class="service_detail_info">
                     <ul>
                        <li class="align-items-center card_service_icon"> <i class="icon-check-sign"></i> <span
                              class="m-l-10">{{sub.class_name}}</span></li>
                        <span>
                           {{sub.item_description}}
                        </span>
                     </ul>
                  </div>
               </div>
            </el-col>
         </el-row>
      </div>
   </div>
   <Footer footerPadding="true" />
</template>

<script>
   import Header from '../components/Home/Header.vue';
   import Breadcrumb from '../components/common/Breadcrumb.vue';
   import Footer from '../components/Home/Footer.vue';

   export default {
      name: 'BlogPage',
      components: {
         Header,
         Breadcrumb,
         Footer,
      },
      data() {
         return {
            lesson: 'm1',
            detailList: [
            {
               value: 'm1',
               title: "Математикийн стандарт хөтөлбөр",
               description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
               detailItems: [{
                     item_title: "Түвшин нөхөх",
                     class_name: "3-12 анги",
                     item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуулийн стандарт хөтөлбөр бүхий ангид суралцдаг сурагчдын хичээлийн агууллагын дутуу орхигдсон, ойлгогдоогүй сэдвүүдээ бүрэн нөхөж ойлгож авах боломжтой."
                  },
                  {
                     item_title: "Түвшин ахиулах",
                     class_name: "3-12 анги",
                     item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуульд суралцаж буй түвшнөөсөө дээш ангийн түвшинд мэдлэгийг олж авах болно."
                  },
                  {
                     item_title: "Түвшин ахиулах",
                     class_name: "3-12 анги",
                     item_description: "Тус сургалт нь математикийн хичээлээс тусдаа сэдэв боловч анги ангийн түшинд хавсран заавал судалсан байх шаардлагатай хичээл тул анги бүрийн алгебрийн хичээлийн хажуугаар хавсран судлах шаардлагатай."
                  }
               ]
            },
            {
               value: 'm2',
               title: "Математикийн гүнзгийрүүлсэн хөтөлбөр",
               description: "Энэхүү хичээлүүдийн агуулга нь математикийн ШУныг дагнан судлах гэж буй болон цаашид цэвэр математикт суурилсан мэргэжлүүдээр мэргэшихээр төлөвлөж буй суралцагчдын судлах агуулгуудыг багтаасан. Энэхүү сургалтанд суралцагчдын оюуны потенциалын чадамж өндөр байхыг шаардана.",
               detailItems: [{
                     item_title: "Ерөнхий эрдэм",
                     class_name: "5-12 анги",
                     item_description: "Уг хичээл нь анги бүрийн түвшин дэх стандарт агуулгыг илүү задаргаатайгаар болон илүү логик хүндрүүлсэн талдаа үзэж судална. Энэ хөтөлбөрийг 1, 11 р сургууль болон энгийн сургуулиудын математикийн гүнзгийрүүлсэн сургалттай ангид суралцагч сурагчид үзэж судална."
                  },
                  {
                     item_title: "Мэргэжлийн курс",
                     class_name: "5-12 анги",
                     item_description: "Энэхүү хичээл нь хичээлийн үндсэн хөтөлбөрөөс гадна илүү академик гүнзгийрүүлсэн хүнд түвшний бодлогуудыг агуулах бөгөөд гүнзгийрүүлэн судалдаг суралцагчид нэмэлтээр бүх төрүлийн математикийн тэмцээн уралдаанд орох, сэтгэн бодох чадварыг мэргэшүүлэн судлахад суралцдаг."
                  },
                  {
                     item_title: "Геометр",
                     class_name: "5-12 анги",
                     item_description: "Уг геометрийн хичээл нь стандарт бүх агуулгыг багтаах боловч түлхүү теорем, чанаруулын баталгаа нотолгоо болон сэтгэлгээ судалгаа илүү шаардсан  хүндрүүлсэн түвшинд судална. Математикийн ШУ наар дагнан мэргэшихээр төлөвлөж буй хэн бүхний заавал судлах хичээл."
                  }
               ]
            },
            {
               value: 'm3',
               title: "Дотоодын төрөлжсөн шалгалтанд бэлтгэх хөтөлбөр",
               description: "Энэхүү хөтөлбөрт хамрагдах суралцагч нь стандарт түвшний хоцрогдолгүй байхыг шаардах бөгөөд мөн суралцах хугацаатай хангалттай байх шаардлагатай",
               detailItems: [{
                     item_title: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх1 (Шинэ эрин, Шинэ эхлэл)",
                     class_name: "3-5 анги",
                     item_description: "Энэхүү хөтөлбөр нь 3 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана."
                  },
                  {
                     item_title: "ОУ-ын хөтөлбөрт сургуулийн элсэлтэнд бэлтгэх2 ( Монгол тэмүүлэл)",
                     class_name: "7-8 анги",
                     item_description: "Энэхүү хөтөлбөр нь 6 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана."
                  },
                  {
                     item_title: "Математикийн гүнзгийрүүлсэн сургуульд бэлтгэх (1,11 дүгээр сургууль болон бүх сургуулийн математикийн гүнзгийрүүлсэн ангид бэлтгэх)",
                     class_name: "3-5 анги",
                     item_description: "Энэхүү хөтөлбөр нь 3 р ангиас эхлэн бэлтгэгдэх боломжтой бөгөөд суралцаж буй ангийн түвшинд огт хоцрогдолгүй, унших хурд сайтай, ойлгох чадвар өндөртэй, дэлгэцийн хамааралгүй, хувийн зохион байгуулалттай байхыг шаардана."
                  },
                  {
                     item_title: "ЭЕШ-нд бэлтгэх",
                     class_name: "11-12 анги, насанд хүрэгчид",
                     item_description: "Энэхүү хөтөлбөр нь суралцагчийг суралцаж буй ангийн түвшинд хүртэл алгебр болон геометрийн хичээлийнх ямар нэгэн хоцрогдолгүй байхыг шаардах бөгөөд хэрэв хоцрогдолтой байгаа нөхцөлд түвшин нөхөх сургалтанд хамрагдсаны дараагаар цэвэр ЭЕШ ийн бэлтгэл сургалтанд хамрагдах боломжтой."
                  }
               ]
            },
            {
               value: 'm4',
               title: "Олон улсын төрөлжсөн шалгалтанд бэлтгэх хөтөлбөр",
               description: "Энэхүү хөтөлбөрт хамрагдах суралцагч англи хэлний дундаас дээш түвшний мэдлэгтэй уншаад бүрэн ойлгох чадвартай, ангийн түвшинд стандарт хөтөлбөрийн хоцрогдолгүй байх шаардлагатай.",
               detailItems: [{
                     item_title: "ОУ-ын Засгийн газрын тэтгэлэгт шалгалтанд бэлтгэх",
                     class_name: "11-12 анги",
                     item_description: "БНХАУ, ОХУ, Япон, Солонгос гэх мэт улсуудаас зарлагддаг засгийн газрын тэтгэлэгт шалгалтуудад улс орон болгоных нь шалгалтын онцлогт тохируулсан хөтөлбөрөөр бэлтгэдэг."
                  },
                  {
                     item_title: "ОУын хөтөлбөрт сургуулиудын сурагчдын өгдөг шалгалтуудад бэлтгэх",
                     class_name: "8-12 анги. насанд хүрэгчид",
                     item_description: "Олон улсын хөтөлбөр бүхий сургуулиудын суралцагчдын өгдөг IGCSE, AS level, A lelev, Statistics болон бакалавр магистрын түвшинд элсэн суралцагчдын өгдөг SAT, GRE, GMAT шалгалтуудад бэлтгэх хөтөлбөр."
                  }
               ]
            },
            {
               value: 'm5',
               title: "Дээд математикийн хөтөлбөр",
               description: "Эдгээр хөтөлбөрүүд нь зөвхөн монгол улсад төдийгүй олон улсад ижил хөтөлбөрийн дагуу явагддаг бөгөөд гадны аль ч сургуульд дээрх мэргэжлээр суралцаж байгаа оюутнууд монгол хэлээрээ заалгуулах боломжтой. Энэхүү хөтөлбөр хамрагдах сурагчид ЕБС-ын математик болон геометрийн хичээлийн хоцрогдолгүй байх шаардлагатай.",
               detailItems: [{
                     item_title: "Хэрэглээний математик 1,2,3",
                     class_name: "Насанд хүрэгч",
                     item_description: "Энэхүү хөтөлбөр нь математикийн төрөлжсөн бус ч математикийн суурь хичээл болон ордог их сургуулийн 1, 2 р курсын оюутнууд суралцах боломжтой."
                  },
                  {
                     item_title: "Эдийн засгийн математик",
                     class_name: "Насанд хүрэгч",
                     item_description: "Энэхүү хөтөлбөр нь их сургуульд эдийн засаг, санхүү , менежментийн чиглэлээр суралцаж байгаа оюутнуудад зориулагдсан."
                  },
                  {
                     item_title: "Инженерийн математик",
                     class_name: "Насанд хүрэгч",
                     item_description: "Энэхүү хөтөлбөр нь их сургуульд инженер чиглэлээр суралцаж байгаа оюутнуудад зориулагдсан."
                  }
               ]
            },{
                   value: 'e1',
                   title: "Англи хэлний стандарт хөтөлбөр",
                  //  description: "Түвшин ахиулах",
                   detailItems: [{
                         item_title: "Түвшин ахиулах",
                         class_name: "A1,A2",
                         item_description: "Анхан шатны ойлголт, Анхан шатны хэрэглээ, Англиар сэтгэх Үндсэн 12 дүрэм, Шинэ үгс."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "B1,B2",
                         item_description: "Дунд болон Анхан дунд шат, Шинжлэх ухааны үгс болон ярианы ашиглалт."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "C1,C2",
                         item_description: "Ахисан болон гүнзгий Академик түвшний үгсийн бичгийн ярианы хэрэглээ."
                      }
                   ],
            },
            {
               value: 'e2',
               title: "Англи хэлний гүнзгийрүүлсэн хөтөлбөр",
            //  description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
               detailItems: [{
                     item_title: "9-р ангийн элсэлт",
                     class_name: "A1,A2 B1,B2",
                     item_description: "МУ ын ЕБС ийн 9-р ангид элсэн ороход бэлтгэх."
                  },
                  {
                     item_title: "ЭЕШ",
                     class_name: "A2-C1",
                     item_description: "Монгол улсын их сургуулиудын шалгалтад бэлтгэх анги."
                  },
                  {
                     item_title: "Лицей",
                     class_name: "A2-C1",
                     item_description: "Их сургуулиудын харьяа ахлах сургуулиудад бэлтгэх."
                  }
               ],
            },{
               value: 'e3',
               title: "Англи хэлний олон улсын хөтөлбөр",
               //description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
               detailItems: [{
                     item_title: "IELTS",
                     class_name: "B1,2-C1,2",
                     item_description: "Англи хэлний хэрэглээний ур чадвар шалгах шалгалтад бэлдэх."
                  },
                  {
                     item_title: "Түвшин ",
                     class_name: "B1,2-C1,2",
                     item_description: "Кэмбриджийн сургалттай сургуулиудад IGCSE хөтөлбөрт бэлтгэх сургалт."
                  }
               ],
            },
      ],
         }
      },
      created() {
         this.renderView()
      },
      methods: {
         renderView() {
            if (this.$route.params.data) {
               this.lesson = this.$route.params.data
            } else {
               this.$router.push('/home')
            }
         },
      }
   }
</script>