import { createStore } from 'vuex';
const reading_status = window.localStorage.getItem('reading_status')

const store = createStore({
    state: {
        is_reading:reading_status ? JSON.parse(reading_status) : true,
     },
     mutations: {
         changeStatusPuzzle (state, status) {
             state.is_reading = status
             window.localStorage.setItem('reading_status', JSON.stringify(state.is_reading))
         }
     },
     getters: {
         puzzleReadStatus (state) {
             return state.is_reading
         }
     }
  
    })

export default store