<template>
<section class="testimonial__area testimonial__overlay pt-40 pb-40 " style="background-image: url('https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/2_1.jpg')">
   <div class="container">
   <div class="col-xxl-12">
   <div class="testimonial__slider swiper-container">
       <swiper
        ref="mySwiper"
        :modules="modules"
        :loop="true"
        :navigation="{nextEl:'.swiper-button-next',prevEl:'.swiper-button-prev'}"
      >
        <swiper-slide v-for="testimonial in testimonialData" :key="testimonial.id">
          <div class="testimonial__slider-inner swiper-wrapper">
               <div class="testimonial__item text-center swiper-slide">
                       <div class="testimonial__thumb">
                     <img :src="testimonial.img_url" alt="">
                  </div>
                  <div class="testimonial__content">
                     <p>{{testimonial.comments}}</p>

                     <div class="testimonial__info">
                        <h4>{{testimonial.user_name}}</h4>
                        <span>{{testimonial.social_channel}}</span>
                     </div>
                  </div>
               </div>
            </div>
        </swiper-slide>

         <div class="swiper-button-next swiper-nav">
         <i class="far fa-arrow-right"></i>
      </div>
      <div class="swiper-button-prev swiper-nav">
         <i class="far fa-arrow-left"></i>
      </div>
      </swiper>

     

         </div>
      </div>
   </div>
</section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, EffectFade } from "swiper";

export default {
   name:'TestimonialArea',
   data () {
      return {
         testimonialData:[
            {
               id:1,
               comments:'“Аугаа хүн гэдэг нь хамгийн их хичээж чадсан энгийн хүн юм.”',
               user_name: 'А.Молор-Эрдэнэ',
               img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/images/319970395_717816036578016_772949684823845560_n.png",
               social_channel:'Математикийн дасгалжуулагч'
            },
            {
               id:2,
                 comments:'“Бусдыг дийлдэг хүнийг хүчтэй, өөрийгөө дийлдэг хүнийг ухаантай гэдэг.”',
              user_name: 'Б.Билгүүн',
              img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Bilgvvn.JPG",
               social_channel:'Англи хэлний дасгалжуулагч'
            },
            {
               id:3,
                 comments:'“Чи чадахгүй гэж бодож байвал чиний зөв. Чи чадахгүй. Харин чи чадна гэж бодож байвал бас л чиний зөв. Чи чадна.”',
              user_name: 'Н.Алтанзаяа',
              img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Altanzaya.JPG",
               social_channel:'Математикийн дасгалжуулагч'
            },
            {
               id:4,
                 comments:'“Би чаддаггүй гэж бодох тэр үед эхэл. Удахгүй тэр бодол алга болно.”',
              user_name: 'Г.Мөнхзаяа',
              img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Monhzaya.JPG",
               social_channel:'Математикийн дасгалжуулагч'
            },
            {
               id:5,
                 comments:'“Би азын тэнгэр байдагт бүрэн итгэдэг. Их ажиллах тусам их аз дайрдагийг би ажигласан.”',
              user_name: 'Б.Хосбаяр',
              img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Hosbayar.JPG",
               social_channel:'Англи хэлний дасгалжуулагч'
            },
            {
               id:6,
                 comments:'“Хамгийн хэцүү шийдвэр нь хийж эхлэх. Бусад нь бол ердөө л тууштай зан.”',
              user_name: 'А.Болортуяа',
              img_url: "https://dmafilemanagement.s3.ap-southeast-1.amazonaws.com/teachers/ishlel_Bolortuya.JPG",
               social_channel:'Математикийн ахлах дасгалжуулагч'
            }
         ]
      }
   },
   components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, EffectFade ],
    };
  },
};
</script>
