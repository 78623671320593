<template>
  <Header headerShadow="header__area header__padding header__padding-2 header__shadow " />
  <CourseDetailsArea :detail="detail"/>
  <Footer footerPadding="true"/>
</template>

<script>
import Header from '../components/Home/Header.vue';
import CourseDetailsArea from '../components/CourseDetails/CourseDetailsArea.vue';
import Footer from '../components/Home/Footer.vue';
import axios from 'axios'
import config from '@/config/index'
export default {
	name:'CourseDetails',
	components:{
		Header,
		CourseDetailsArea,
        Footer
	},
	created(){
		this.getLessonDetail()
	},
	data(){
		return {
			detail:{}
		}
	},
	methods:{
		getLessonDetail(){
         var self = this
         var payload = {
            lesson_id : self.$route.query.id
         }
         axios.post(config.BASE_SERVER_PATH + '/getLessonById', payload)
         .then(function (response) {
            console.log(response.data.data)
            self.detail = response.data.data
         })
         .catch(function (error) {
            console.log(error);
         });
      },
	}
};
</script>

