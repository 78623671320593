<template>
    <Header header__white="header__white" />
    <Breadcrumb title="Ангил хэлний хөтөлбөр" subtitle="Ангил хэлний хөтөлбөр" />
    <ServicesEnglish />
    <Footer footerPadding="true" />
 </template>
 
 <script>
    import Header from '../components/Home/Header.vue';
    import Breadcrumb from '../components/common/Breadcrumb.vue';
    import Footer from '../components/Home/Footer.vue';
    import ServicesEnglish from '../components/HomeTwo/ServicesEnglish.vue'
 
    export default {
       name: 'BlogPage',
       components: {
          Header,
          Breadcrumb,
          Footer,
          ServicesEnglish
       },
       data() {
          return {
             lesson: 'mat1',
             detailList: [
                {
                   value: 'e1',
                   title: "Математикийн Стандарт Хөтөлбөр",
                   description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
                   detailItems: [{
                         item_title: "Түвшин нөхөх",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуулийн стандарт хөтөлбөр бүхий ангид суралцдаг сурагчдын хичээлийн агууллагын дутуу орхигдсон, ойлгогдоогүй сэдвүүдээ бүрэн нөхөж ойлгож авах боломжтой."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуульд суралцаж буй түвшнөөсөө дээш ангийн түвшинд мэдлэгийг олж авах болно."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалт нь математикийн хичээлээс тусдаа сэдэв боловч анги ангийн түшинд хавсран заавал судалсан байх шаардлагатай хичээл тул анги бүрийн алгебрийн хичээлийн хажуугаар хавсран судлах шаардлагатай."
                      }
                   ],
                },
                {
                   value: 'e2',
                   title: "Математикийн  Хөтөлбөр",
                   description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
                   detailItems: [{
                         item_title: "Түвшин нөхөх",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуулийн стандарт хөтөлбөр бүхий ангид суралцдаг сурагчдын хичээлийн агууллагын дутуу орхигдсон, ойлгогдоогүй сэдвүүдээ бүрэн нөхөж ойлгож авах боломжтой."
                      },
                      {
                         item_title: "Түвшин ",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуульд суралцаж буй түвшнөөсөө дээш ангийн түвшинд мэдлэгийг олж авах болно."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалт нь математикийн хичээлээс тусдаа сэдэв боловч анги ангийн түшинд хавсран заавал судалсан байх шаардлагатай хичээл тул анги бүрийн алгебрийн хичээлийн хажуугаар хавсран судлах шаардлагатай."
                      }
                   ],
                },{
                   value: 'e3',
                   title: "Математикийн  Хөтөлбөр",
                   description: "Энэ бүлэг нь гүнзгийрүүлсэн хөтөлбөр бүхий ангийн хүүхдүүдэд хамаарахгүй. Хүсвэл энэхүү түвшнөө дүүргээд гүнзгийрүүлсэн хөтөлбөрт хамрагдах шаардлагыг хангасан нөхцөлд гүнзгийрүүлэн судлах бүрэн боломжтой. Энэхүү сургалт нь суралцагчийн хүлээж авах хурд, оюуны чадавхид тулгуурлан хүн тус бүрт тохирсон хөтөлбөрөөр явагдана.",
                   detailItems: [{
                         item_title: "Түвшин нөхөх",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуулийн стандарт хөтөлбөр бүхий ангид суралцдаг сурагчдын хичээлийн агууллагын дутуу орхигдсон, ойлгогдоогүй сэдвүүдээ бүрэн нөхөж ойлгож авах боломжтой."
                      },
                      {
                         item_title: "Түвшин ",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалтанд хамрагдсанаар ерөнхий боловсролын сургуульд суралцаж буй түвшнөөсөө дээш ангийн түвшинд мэдлэгийг олж авах болно."
                      },
                      {
                         item_title: "Түвшин ахиулах",
                         class_name: "3-12 анги",
                         item_description: "Тус сургалт нь математикийн хичээлээс тусдаа сэдэв боловч анги ангийн түшинд хавсран заавал судалсан байх шаардлагатай хичээл тул анги бүрийн алгебрийн хичээлийн хажуугаар хавсран судлах шаардлагатай."
                      }
                   ],
                },
             ]
          }
       },
       created() {},
       methods: {}
    }
 </script>